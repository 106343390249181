export const mobileMenuHandler = () => {
	const closeMobileMenu = () => {
		let element = document.getElementById("mobile_nav_hamburger")
		if (element) {
			element.classList.remove("open")
		}

		let menu = document.getElementById("mobile-menu")
		if (menu) {
			menu.classList.remove("mobile-menu-on")
		}

		let overlay = document.getElementById("mobile-menu-overlay")
		if (overlay) {
			overlay.classList.remove("d-block")
		}
	}

	const toggleMobileMenu = () => {
		let element = document.getElementById("mobile_nav_hamburger")
		element.classList.toggle("open")

		let menu = document.getElementById("mobile-menu")
		menu.classList.toggle("mobile-menu-on")

		let overlay = document.getElementById("mobile-menu-overlay")
		overlay.classList.toggle("d-block")
	}

	return {closeMobileMenu, toggleMobileMenu}
}
