import {saveUrlParams, constructParams} from "@/functions/urlParams"

const appName = process.env.VUE_APP_SITENAME
const appYear = process.env.VUE_APP_TAX_YEAR
const minActivePriorYear = appYear - 7
const currentDateYear = new Date().getFullYear()
const numberReturnsFiled = "71,000,000+"
let authSubdomain = process.env.VUE_APP_AUTH_SUBDOMAIN
let subdomain = process.env.VUE_APP_SUBDOMAIN
let env = process.env.VUE_APP_ENV_NAME
let localDomain = process.env.VUE_APP_LOCAL_DOMAIN

saveUrlParams()

if (sessionStorage.getItem("share")) {
	sessionStorage.setItem("utm_source", "share")
}

let params = constructParams()

let paramsNoAmp
paramsNoAmp = "?" + params

let appBaseUrl = "https://" + authSubdomain + "." + appName + ".com"
//test code for nginx-proxy server
if (env === "dev") {
	appBaseUrl = "https://" + authSubdomain + "." + appName + localDomain + ":8443" // The missing '.' before localDomain is intentional - it's re-added when needed
}

let currentYearParam = "&appYear=" + appYear

let priorYearBaseUrl = appBaseUrl + "?appYear="
let priorYear2023Url = priorYearBaseUrl + "2023&NEWTAX" + params
let priorYear2022Url = priorYearBaseUrl + "2022&NEWTAX" + params
let priorYear2021Url = priorYearBaseUrl + "2021&NEWTAX" + params
let priorYear2020Url = priorYearBaseUrl + "2020&NEWTAX" + params
let priorYear2019Url = priorYearBaseUrl + "2019&NEWTAX" + params
let priorYear2018Url = priorYearBaseUrl + "2018&NEWTAX" + params
let priorYear2017Url = priorYearBaseUrl + "2017&NEWTAX" + params

let oldAppUrl = priorYearBaseUrl + (appYear - 1) + params
let newUrl = appBaseUrl + "?NEWTAX" + params + currentYearParam
let paidPreparerUrl = appBaseUrl + "?NEWTAX&PAIDPREPARER" + params + currentYearParam
let deluxeUrl = appBaseUrl + "?DLX&NEWTAX" + params + currentYearParam
let auditDefenseUrl = appBaseUrl + "?AD&NEWTAX" + params + currentYearParam
let continueUrl = appBaseUrl + paramsNoAmp + currentYearParam

let amendedBaseUrl = appBaseUrl + "?AMENDED&goto_page=106" + params + "&appYear="
let amendedUrl = amendedBaseUrl + appYear
let amended2023Url = amendedBaseUrl + "2023"
let amended2022Url = amendedBaseUrl + "2022"
let amended2021Url = amendedBaseUrl + "2021"
let amended2020Url = amendedBaseUrl + "2020"
let amended2019Url = amendedBaseUrl + "2019"
let amended2018Url = amendedBaseUrl + "2018"
let amended2017Url = amendedBaseUrl + "2017"
let amended2016Url = amendedBaseUrl + "2016"

let checkUrl = appBaseUrl + "?goto_page=50" + params + currentYearParam
let proSupportUrl = appBaseUrl + "?NEWTAX&PROSUPPORT" + params + currentYearParam
let contactSupportDeluxeUrl = appBaseUrl + "?goto_page=7&" + params + currentYearParam
let contactSupportUrl = appBaseUrl + "?goto_page=7" + params + currentYearParam
let extUrl = appBaseUrl + "?NEWTAX&NEWEXT" + params + currentYearParam
let emailUrl = appBaseUrl + "?goto_page=60" + params + currentYearParam
let signInHelpUrl = appBaseUrl + "/ReclaimAccount" + "?" + params + currentYearParam
let creditUrl = appBaseUrl + "?goto_page=547" + params + currentYearParam
let printUrl = appBaseUrl + "?goto_page=100001" + params + currentYearParam
let downloadPath = "https://" + subdomain + "." + appName + ".com/forms/"

export const constants = {
	amendedUrl: amendedUrl,
	amended2023Url: amended2023Url,
	amended2022Url: amended2022Url,
	amended2021Url: amended2021Url,
	amended2020Url: amended2020Url,
	amended2019Url: amended2019Url,
	amended2018Url: amended2018Url,
	amended2017Url: amended2017Url,
	amended2016Url: amended2016Url,
	appName: appName,
	appYear: appYear,
	minActivePriorYear: minActivePriorYear,
	currentDateYear: currentDateYear,
	priorYear2023Url: priorYear2023Url,
	priorYear2022Url: priorYear2022Url,
	priorYear2021Url: priorYear2021Url,
	priorYear2020Url: priorYear2020Url,
	priorYear2019Url: priorYear2019Url,
	priorYear2018Url: priorYear2018Url,
	priorYear2017Url: priorYear2017Url,
	checkUrl: checkUrl,
	contactSupportDeluxeUrl: contactSupportDeluxeUrl,
	contactSupportUrl: contactSupportUrl,
	continueUrl: continueUrl,
	creditUrl: creditUrl,
	deluxeUrl: deluxeUrl,
	emailUrl: emailUrl,
	extUrl: extUrl,
	newUrl: newUrl,
	auditDefenseUrl: auditDefenseUrl,
	proSupportUrl: proSupportUrl,
	signInHelpUrl: signInHelpUrl,
	printUrl: printUrl,
	oldAppUrl: oldAppUrl,
	numberReturnsFiled: numberReturnsFiled,
	downloadPath: downloadPath,
	paidPreparerUrl: paidPreparerUrl
}
