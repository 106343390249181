export const setRefPage = () => {
	if (!sessionStorage.getItem("refPage")) {
		if (document.referrer) {
			sessionStorage.setItem("refPage", document.referrer)
		}
		else {
			let refPage = location.href.split("?")[0]
			sessionStorage.setItem("refPage", refPage)
		}
	}
}
