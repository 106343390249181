<template>
	<div id="mobile-menu" class="mobile-menu d-flex flex-column flex-row-auto">
		<div class="mobile-menu-wrapper flex-column-fluid">
			<div class="mobile-menu-container mt-4 pb-4" data-menu-dropdown-timeout="500" data-menu-scroll="1" data-menu-vertical="1">
				<ul id="mobileAccordion" class="mobile-menu-nav accordion">
					<li class="mobile-menu-item accordion-item">
						<button aria-controls="collapseOne" aria-expanded="true" class="accordion-button collapsed" data-bs-target="#collapseOne" data-bs-toggle="collapse" type="button">
							<span class="mobile-menu-title"><span class="menu-text">Pricing & Products</span></span>
						</button>
						<div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#mobileAccordion">
							<div class="accordion-body">
								<router-link class="mobile-menu-link" to="/pricing" @click="toggleMobileMenu">
									<span class="menu-text">Pricing</span></router-link>
								<router-link class="mobile-menu-link" to="/software" @click="toggleMobileMenu">
									<span class="menu-text">Our Free Federal</span></router-link>
								<router-link class="mobile-menu-link" to="/deluxe" @click="toggleMobileMenu">
									<span class="menu-text">Deluxe</span></router-link>
								<router-link class="mobile-menu-link" to="/pro" @click="toggleMobileMenu">
									<span class="menu-text">Pro Support</span></router-link>
								<router-link class="mobile-menu-link" to="/audit" @click="toggleMobileMenu">
									<span class="menu-text">Audit Defense</span></router-link>
								<router-link class="mobile-menu-link" to="/extension" @click="toggleMobileMenu">
									<span class="menu-text">Tax Extensions</span></router-link>
								<router-link class="mobile-menu-link" to="/prior-year" @click="toggleMobileMenu">
									<span class="menu-text">Prior Year Returns</span>
								</router-link>
							</div>
						</div>
					</li>
					<li class="mobile-menu-item accordion-item">
						<button aria-controls="collapseTwo" aria-expanded="false" class="accordion-button collapsed" data-bs-target="#collapseTwo" data-bs-toggle="collapse" type="button">
							<span class="mobile-menu-title"><span class="menu-text">Support</span></span>
						</button>
						<div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#mobileAccordion">
							<div class="accordion-body">
								<router-link class="mobile-menu-link" to="/support" @click="toggleMobileMenu">
									<span class="menu-text">Customer Support</span></router-link>
								<a :href="constants.signInHelpUrl" class="mobile-menu-link" @click="toggleMobileMenu"><span class="menu-text">Forgot Username or Password?</span></a>
								<a :href="constants.contactSupportUrl" class="mobile-menu-link" @click="toggleMobileMenu"><span class="menu-text">My Support Issues</span></a>
								<a class="mobile-menu-link" href="https://community.freetaxusa.com/" target="_blank"><span class="menu-text">Community Answers & Help</span></a>
							</div>
						</div>
					</li>
					<li class="mobile-menu-item accordion-item">
						<button aria-controls="collapseThree" aria-expanded="false" class="accordion-button collapsed" data-bs-target="#collapseThree" data-bs-toggle="collapse" type="button">
							<span class="mobile-menu-title"><span class="menu-text">Prior Year</span></span>
						</button>
						<div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#mobileAccordion">
							<div class="accordion-body">
								<router-link class="mobile-menu-link" to="/prior-year" @click="toggleMobileMenu">
									<span class="menu-text">All Years 2017-2023</span>
								</router-link>
								<router-link class="mobile-menu-link" to="/2023" @click="toggleMobileMenu">
									<span class="menu-text">File 2023 Tax Return</span>
								</router-link>
								<router-link class="mobile-menu-link" to="/2022" @click="toggleMobileMenu">
									<span class="menu-text">File 2022 Tax Return</span>
								</router-link>
								<router-link class="mobile-menu-link" to="/2021" @click="toggleMobileMenu">
									<span class="menu-text">File 2021 Tax Return</span>
								</router-link>
								<a :href="constants.printUrl" class="mobile-menu-link" @click="toggleMobileMenu"><span class="menu-text">View My Prior Year Return(s)</span></a>
							</div>
						</div>
					</li>
					<li class="mobile-menu-item accordion-item">
						<button aria-controls="collapseFour" aria-expanded="false" class="accordion-button collapsed" data-bs-target="#collapseFour" data-bs-toggle="collapse" type="button">
							<span class="mobile-menu-title"><span class="menu-text">After You File</span></span>
						</button>
						<div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#mobileAccordion">
							<div class="accordion-body">
								<a :href="constants.checkUrl" class="mobile-menu-link" @click="toggleMobileMenu"><span class="menu-text">Check E-File Status</span></a>
								<a :href="constants.printUrl" class="mobile-menu-link" @click="toggleMobileMenu"><span class="menu-text">Print / Download My Return</span></a>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>

	<div id="mobile-menu-overlay" class="mobile-menu-overlay" @click="toggleMobileMenu"></div>
</template>

<script setup>
import {onMounted, watch} from "vue"
import {useRoute} from "vue-router"
import {constants} from "@/functions/constants"
import {mobileMenuHandler} from "@/functions/menuHandlerMobile"
import {useEventListener} from "@/functions/useEventListener"

const {closeMobileMenu, toggleMobileMenu} = mobileMenuHandler()
const route = useRoute()

onMounted(() => {
	document.addEventListener("keydown", (evt) => {
		if (evt.key === "Escape") {
			closeMobileMenu()
		}
	})

	watch(route, () => {
		closeMobileMenu()
	})
})

useEventListener(window, "DOMContentLoaded", closeMobileMenu)
useEventListener(window, "resize", closeMobileMenu)
</script>
