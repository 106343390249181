<template>
	<section class="section-padding">
		<div class="container pt-5">
			<div class="row d-flex justify-content-center align-items-center">
				<div class="spinner-grow" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</div>
		</div>
	</section>
</template>
