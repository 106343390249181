export const runMatomo = () => {
	let _paq = window._paq = window._paq || [];
	_paq.push(["setCustomUrl", location.pathname])
	_paq.push(["setDocumentTitle", location.pathname + " - " + document.title])
	_paq.push(["setCookieDomain", "*.freetaxusa.com"])
	_paq.push(["setDomains", ["*.freetaxusa.com"]])
	_paq.push(["enableCrossDomainLinking"])
	_paq.push(["enableLinkTracking"])
	_paq.push(["trackPageView"])
}
